<template>
  <div class="product-card">
    <corner-ribbon v-if="product.ribbon">{{ product.ribbon }}</corner-ribbon>
    <div>
      <p>{{ product.name }}</p>
      <h3 class="is-size-3">
        <strong>{{
          $_.get(product, `pricePoint.${computedCurrency}`, `--`)
        }}</strong>
      </h3>
      <p class="has-margin-top-50">{{ product.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    product: {
      type: Object,
      required: true
    },
    currency: {
      type: String,
      default: () => {
        return null;
      }
    }
  },
  computed: {
    computedCurrency() {
      return this.currency || this.$store.getters["user/currency"]();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.product-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1.5em;
  border: 2px dotted $grey-lighter;
  border-radius: 0.5em;
  cursor: pointer;
  transition-property: transform;
  transition-timing-function: ease-out;
  transition-duration: 0.12s;
  &:hover {
    transform: scale(1.025);
  }
  &.is-selected {
    border-color: $success;
    border-style: solid;
    box-shadow: 0 0 0.5em 0 rgba($success, 0.25);
  }
}
</style>
